html,
body {
  height: 100%;
}

div#root {
  height: 100%;
}

.height-100 {
  height: 100%;
}

.icon-circle-badge-bg {
  background-color: #000;
}

.hidden {
  display: none!important;
}

.hidden-space {
  display: inline;
  visibility: hidden;
}